import React from "react"
import { connect, ConnectedProps } from "react-redux"
import { Link } from "gatsby"

import { store } from "@/types/interface"
import { getSpecialCourse } from "../actions/special-course"
import { Layout } from "../components"
import { SEO } from "../components/common"
import styles from "./global-business.module.css"

const connector = connect(
  ({ specialCourse }: { specialCourse: store.SpecialCourse }) => ({
    specialCourse: specialCourse.specialCourse,
  }),
  { getSpecialCourse }
)

type Props = ConnectedProps<typeof connector>

const GlobalBusiness: React.FC<Props> = ({
  specialCourse,
  getSpecialCourse,
}) => {
  React.useEffect(() => {
    getSpecialCourse()
  }, [])

  const youtubeMatch = specialCourse?.youtubeLink.match(
    /https:\/\/(?:www.)?youtube.com\/watch\?v=(.*)/
  )

  return (
    <Layout>
      <div className="container">
        <SEO />
        <h1 className={styles.title}>キッズ・ビジネス</h1>
        {youtubeMatch && (
          <div className={styles.youtubeWrapper}>
            <div className={styles.youtube}>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube-nocookie.com/embed/${youtubeMatch[1]}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
        <div className={styles.actionContainer}>
          <a
            className={styles.onlinePay}
            target="_blank"
            href={specialCourse?.trialPeatixLink}
          >
            一日体験申し込み
            <br />
            (オンライン決済)
          </a>
          <a
            className={styles.onlinePay}
            target="_blank"
            href={specialCourse?.peatixLink}
          >
            本講座申し込み
            <br />
            (オンライン決済)
          </a>
          <Link className={styles.onlineGuide} to="/online-guide">
            オンライン講座ガイド
          </Link>
        </div>
        <img className={styles.image} src={specialCourse?.imagePath} alt="global buisiness" />
      </div>
    </Layout>
  )
}

export default connector(GlobalBusiness)
